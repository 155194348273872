 <template>
  <div>
    <input
      type="file"
      accept="image/*"
      @change="handleFileSelect"
      multiple
      v-if="showUploadButton"
    >
    <!-- <v-row
      v-for="(item) in items"
      :key="item.uid"
    >
      <v-col cols="6">
        <Preview
          :file="item.file"
          :uid="item.uid"
          @click-delete="clickDelete"
        ></Preview>
      </v-col>

    </v-row> -->

    <!-- <v-row>
      <v-col
        cols="12"
        md="3"
        v-for="(item) in items"
        :key="item.uid"
      >
        <Preview
          :file="item.file"
          :uid="item.uid"
          @click-delete="clickDelete"
          :style="getImageItemStyle()"
        ></Preview>
      </v-col>

    </v-row> -->

    <div
      v-for="(item) in items"
      :key="item.uid"
      style="display: inline-block;margin-left:4px"
    >
      <Preview
        :file="item.file"
        :uid="item.uid"
        @click-delete="clickDelete"
      ></Preview>
    </div>

  </div>
</template>

<script>
import { StatusFile } from "../js/constants";
import { getUID } from "../js/custom";
import Preview from "./image-preview-item.vue";

export default {
  components: {
    Preview,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    showUploadButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      // items: [],
    };
  },
  methods: {
    // Function to handle the file input change event
    handleFileSelect(evt) {
      Array.from(evt.target.files).forEach((file) => {
        this.items.push({
          uid: getUID(),
          file: file,
          status: StatusFile.PENDING,
          fileName: file.name,
        });
      });
      this.$emit("all-image", this.items);
    },
    clickDelete(uid) {
      let index = this.items.findIndex((item) => item.uid === uid);
      this.items.splice(index, 1);

      this.$emit("all-image", this.items);
    },
    getImageItemStyle() {
      return {
        width: "200px",
        height: "200px",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      };
    },
  },
  computed: {
    items: {
      get() {
        return this.value || [];
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style>
</style>