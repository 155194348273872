 <template>
  <div
    style="border: 1px solid grey"
    class="pb-12"
  >
    <v-card-text>
      <!-- <div>
        value.uid : {{ value.uid}}
      </div>
      <div>
        uid : {{ uid}}
      </div>
      <div>
        name : {{ value.name}}
      </div>
      <div>
        name : {{ value.issueDate}}
      </div>
      <div>
        id : {{ value.id }}
      </div> -->

      <div class="text-right">
        <v-icon
          class="red--text mt-1 "
          @click="$emit('click-remove',innerValue, index)"
        >mdi-delete</v-icon>
      </div>
      <div class="mb-5"></div>

      <v-row>
        <v-col
          cols="12"
          md="5"
        >
          <v-row>
            <v-col
              cols="12"
              class="my-n2"
            > <render-input
                :input="fieldConfig.name"
                v-model="innerValue.name"
                :disabled="!(model.canEditActivity)"
              ></render-input>
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="my-n1"
            > <render-input
                :input="fieldConfig.issueDate"
                v-model="innerValue.issueDate"
                :disabled="!(model.canEditActivity)"
              ></render-input>
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="my-n1"
            > <render-input
                :input="fieldConfig.cost"
                v-model="innerValue.cost"
                :disabled="!(model.canEditActivity)"
              ></render-input>
            </v-col>

          </v-row>
          <!-- <v-row>
            <v-col class="green">2</v-col>
          </v-row> -->
        </v-col>
        <v-col>
          <v-row>
            <v-col>
              <render-input
                :input="fieldConfig.note"
                v-model="innerValue.note"
                :disabled="!(model.canEditActivity)"
              ></render-input>
              <!-- <div style="height: 100px;"></div> -->
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <input
            type="file"
            accept="image/*"
            multiple
            @change="fileChanges"
          >

          <ImagePreview
            v-if="!value.id"
            class="mt-1"
            @all-image="images=$event"
            :showUploadButton="false"
            v-model="pendingFiles"
          ></ImagePreview>

          <SimpleGallery
            v-if="value.id"
            :key="forceRerenderRequired"
            class="mt-1 mb-10"
            :items="imageList"
            @click-delete="clickRemoveFile"
          ></SimpleGallery>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import moment from "moment";
import { DATE_PICKER_TYPE, InputType, StatusFile } from "../js/constants";
import {
  momentToThaiFormat,
  toStandardFormat,
  validateThaiFormat,
} from "../js/date-helper";
import { getUID } from "../js/custom";
import KachadBloodList from "./kachad-blood-list.vue";
import UploadAttachmentSection from "./global/upload-attachment-section";
import SimpleGallery from "./SimpleGallery.vue";
import ImagePreview from "./image-preview.vue";
import UploadFileMixins from "./../_mixins/upload-file-mixins";

export default {
  components: {
    KachadBloodList,
    UploadAttachmentSection,
    SimpleGallery,
    ImagePreview,
  },
  mixins: [UploadFileMixins],
  props: {
    value: Object,
    model: {
      type: Object,
    },
  },
  data() {
    return {
      pendingFiles: [],
      uid: null,
      imageList: [
        // {
        //   id: "ffc4b9bd-d71a-4e37-2ded-08dbaba4f96b",
        //   src: "https://temjaistorage.blob.core.windows.net/user-upload/20230902065306_%E0%B8%9A%E0%B8%B1%E0%B8%95%E0%B8%A3%E0%B8%99%E0%B8%B1%E0%B8%81%E0%B8%A8%E0%B8%B6%E0%B8%81%E0%B8%A9%E0%B8%B2.JPG",
        //   msrc: "https://temjaistorage.blob.core.windows.net/user-upload/20230902065306_%E0%B8%9A%E0%B8%B1%E0%B8%95%E0%B8%A3%E0%B8%99%E0%B8%B1%E0%B8%81%E0%B8%A8%E0%B8%B6%E0%B8%81%E0%B8%A9%E0%B8%B2.JPG",
        //   size: null,
        //   fileName: "บัตรนักศึกษา.JPG",
        // },
      ],
      forceRerenderRequired: 0,
    };
  },
  watch: {
    "value.id": {
      handler(newVal) {
        let uploadUrl = "/team/Activity/file";
        let appendForm = Object.assign({}, { activityId: this.value.id });

        this.uploadFiles(this.pendingFiles, uploadUrl, appendForm);
      },
    },
  },
  created() {
    this.innerValue.uid = getUID();
    this.uid = this.innerValue.uid;
    this.getData();
  },
  methods: {
    thetiktest() {
      console.log(`uid=${this.innerValue.uid}, id=${this.value.id}`);
    },
    fileChanges(evt) {
      if (!this.value.id) {
        Array.from(evt.target.files).forEach((file) => {
          this.pendingFiles.push({
            uid: getUID(),
            file: file,
            status: StatusFile.PENDING,
            fileName: file.name,
          });
        });
      } else {
        let items = this.handleFileChanges(evt.target.files);

        let uploadUrl = "/team/Activity/file";
        let appendForm = Object.assign({}, { activityId: this.value.id });

        this.uploadFiles(items, uploadUrl, appendForm);
      }
    },
    handleUploadSuccess() {
      if (this.uploadFileModel.completed == this.uploadFileModel.total) {
        this.getData();
      }
    },
    getData() {
      if (!this.value.id) {
        return;
      }
      let url = `/team/Activity/${this.value.id}/files`;

      this.$http.get(url).then((res) => {
        this.imageList = res.items;
      });
    },
    clickRemoveFile(item) {
      let url = `/team/Activity/File?fileId=${item.id}`;

      this.$http.delete(url).then((res) => {
        let removedItems = _.remove(this.imageList, (image) => {
          return image.id === item.id;
        });

        this.forceRerenderRequired++;
      });
    },
  },
  computed: {
    innerValue: {
      get() {
        return this.value || [];
      },
      set(val) {
        this.$emit("input", val);
      },
    },

    fieldConfig() {
      return {
        name: {
          name: "name",
          text: "รายละเอียด",
          type: InputType.text,
          rule: {
            required: true,
          },
        },
        issueDate: {
          name: "issueDate",
          text: "วันที่",
          type: InputType.datePicker,
          rule: {
            required: true,
          },
        },
        cost: {
          name: "cost",
          text: "ค่าใช้จ่าย",
          type: InputType.number,
        },
        note: {
          name: "note",
          text: "หมายเหตุ",
          type: InputType.textArea,
        },
      };
    },
  },
};
</script>

<style>
</style>