<template>
  <div>
    <v-row>
      <v-col>
        <v-progress-linear
          color="success"
          :value="percent"
          v-show="total > 0"
          height="20"
          reactive
          class="white--text"
        >
          <strong>{{ Math.ceil(percent) }}%</strong>
        </v-progress-linear>
      </v-col>
    </v-row>

    <v-row class="nav-table mt-1">
      <v-col>
        <UploadFileButton
          icon="mdi-cloud-upload"
          text="Choose files"
          :multiple="multiple"
          @changedFile="handleFileChanges"
          class="d-inline-block"
        ></UploadFileButton>

        <v-btn
          text
          class="primary ml-2"
          dark
          color="teal"
          v-if="fail > 0 && completed === total"
          @click="retryFail"
        >
          <v-icon left>mdi-refresh</v-icon>Retry Upload Failed
        </v-btn>
      </v-col>
    </v-row>

    <div
      v-if="total > 0"
      class="mt-5"
    >
      <v-alert
        color="cyan"
        border="left"
        elevation="2"
        colored-border
        width="390"
        class="mb-n2"
      >
        <span>
          <b>Total</b>
          <v-icon small>mdi-arrow-right-bold</v-icon>
          {{ total | currency(0) }}
        </span>
        <span class="ml-3 success--text">
          <b>Success</b>
          <v-icon small>mdi-arrow-right-bold</v-icon>
          {{ success | currency(0) }}
        </span>
        <span class="ml-3 error--text">
          <b>Fail</b>
          <v-icon small>mdi-arrow-right-bold</v-icon>
          {{ fail | currency(0) }}
        </span>
      </v-alert>
    </div>

    <v-simple-table
      id="uploadfile-table"
      class="mt-5"
      dense
    >
      <thead>
        <tr>
          <th
            class="text-center"
            width="10"
          >No.</th>
          <th>File Name</th>
          <th>Status</th>
          <th
            class="text-center"
            width="10"
            v-if="showRemove"
          >Action</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in items"
          :key="index"
        >
          <td class="text-center">{{ index + 1 }}</td>
          <td>
            <span v-if="item.fileUrl">
              <!-- <v-btn color="info" text @click="downloadFile(item)">{{item.fileName}}</v-btn> -->
              <a @click="downloadFile(item)">{{ item.fileName }}</a>
            </span>
            <span v-else>{{ item.fileName }}</span>
          </td>
          <td :class="{ 'error--text': item.status === statusFile.FAIL }">
            {{ item.status }}
          </td>
          <td v-if="showRemove">
            <v-icon
              color="error"
              @click="remove(item, index)"
            >mdi-delete</v-icon>
          </td>
        </tr>
        <tr
          v-show="items.length === 0"
          class="border-no-result"
        >
          <td
            colspan="100%"
            class="text-center"
          >No Result</td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import { StatusFile } from "../../js/constants";
import { getUID } from "../../js/custom";
import * as custom from "../../js/custom";
import CustomUploadFile from "./../../components/custom/custom-uploadfile";
import UploadFileButton from "./../../components/custom/uploadfile-button";
import { fakeAbbr, getFakeUrl } from "../../_helpers/fake-url";

const Concurrent = 3;

const downloadApproach = {
  newTab: "new-tab",
  urlInItemPost: "url-in-item-post",
};

export default {
  props: {
    getUrl: String,
    uploadUrl: String,
    removeUrl: String,
    httpOptions: Object,
    clearBeforeUpload: {
      type: String,
      default: "onlyFail",
      validator: function (value) {
        // The value must match one of these strings
        return ["all", "onlyFail"].indexOf(value) !== -1;
      },
    },
    downloadApproach: {
      type: String,
      default: downloadApproach.urlInItemPost,
      validator: function (value) {
        // The value must match one of these strings
        return _.toArray(downloadApproach).indexOf(value) !== -1;
      },
    },
    showRemove: {
      type: Boolean,
      default: true,
    },
    appendForm: Object,
    multiple: {
      type: Boolean,
      default: true,
    },
    retry: {
      type: Number,
      default: 3,
    },
  },
  components: {
    "custom-uploadfile": CustomUploadFile,
    UploadFileButton,
  },
  data() {
    return {
      items: [],
      statusFile: StatusFile,
      completed: 0,
      total: 0,
      success: 0,
      fail: 0,
    };
  },
  created() {
    this.getFiles();
  },
  methods: {
    getFiles() {
      if (!this.getUrl) {
        return;
      }

      this.$http.get(this.getUrl).then((res) => {
        this.items = res.items;
      });
    },
    clearTable() {
      if (this.clearBeforeUpload === "all") {
        this.items.length = 0;
      } else if (this.clearBeforeUpload === "onlyFail") {
        //clone
        let statusVerified = [StatusFile.UPLOADED];
        let fileUploaded = this.items.filter((x) =>
          statusVerified.includes(x.status)
        );
        let cloneArr = [...fileUploaded];
        //clear all
        this.items.length = 0;
        //add uploaded
        this.items.push(...cloneArr);
      }
    },
    getUploadedFiles() {
      let files = this.items.filter((x) => x.status === StatusFile.UPLOADED);
      return files;
    },
    handleFileChanges(files) {
      this.clearTable();

      Array.from(files).forEach((file) => {
        const item = {};
        item.file = file;
        item.status = StatusFile.PENDING;
        item.fileName = file.name;

        this.items.push(item);
      });

      this.uploadFiles();
    },
    retryFail() {
      let failFiles = this.items.filter((x) => x.status === StatusFile.FAIL);
      let cloneArr = [...failFiles];
      this.clearTable();
      cloneArr.forEach((file) => {
        file.status = StatusFile.PENDING;
      });
      this.items.splice(0, 0, ...cloneArr);
      this.uploadFiles();
    },
    uploadFiles() {
      let pendingFiles = this.items.filter(
        (x) => x.status === StatusFile.PENDING
      );

      this.total = pendingFiles.length;
      this.completed = 0;
      this.success = 0;
      this.fail = 0;
      let retry = this.retry;

      let start = new Date().getTime();
      let splited = custom.chunkArray(pendingFiles, Concurrent);

      splited.forEach((aChunk) => {
        custom.asyncForEach(aChunk, async (item, index) => {
          const formData = new FormData();
          formData.append("file", item.file);
          formData.append("fileName", item.fileName);
          if (this.appendForm) {
            for (const prop in this.appendForm) {
              formData.append(prop, this.appendForm[prop]);
            }
          }

          item.uid = custom.getUID();
          await this.createRequest(formData, item.uid, retry);

          if (this.completed == this.total) {
            let end = new Date().getTime();
            let time = end - start;
            console.log(
              "Execution time: " + custom.millisToMinutesAndSeconds(time)
            );
          }
        });
      });
    },

    createRequest(formData, uid, retry) {
      let options = this.httpOptions || {};
      let url = this.uploadUrl;

      return this.$http
        .uploadFile(url, formData, options)
        .then((res) => {
          this.updateStatus(uid, StatusFile.UPLOADED, res);
          this.completed++;
          this.success++;
          this.$emit("upload-success", { uid, items: this.items, res });
        })
        .catch((err) => {
          this.clearAllAlert();
          if (retry) {
            retry--;
            this.createRequest(formData, uid, retry);
          } else {
            this.updateStatus(uid, StatusFile.FAIL);
            this.completed++;
            this.fail++;
          }
        })
        .finally(() => {});
    },
    updateStatus(uid, status, newObject) {
      let oldObject = this.items.find((item) => item.uid === uid);
      let merged = Object.assign(oldObject, newObject);
      merged.status = status;

      let index = this.items.findIndex((item) => item.uid === uid);
      this.items.splice(index, 1, merged);
    },
    remove(item, index) {
      let url = "";
      if (this.removeUrl && item.id) {
        url = `${this.removeUrl}/${item.id}`;
      } else if (item.fileUrl) {
        url = item.fileUrl;
      }

      if (!item.id) {
        this.items.splice(index, 1);
        return;
      }

      if (!url) {
        return;
      }

      this.$http.delete(url).then((res) => {
        this.items.splice(index, 1);
      });

      // if (item.fileUrl) {
      //   this.$http.delete(item.fileUrl).then(res => {
      //     this.items.splice(index, 1);
      //   });
      // } else {
      //   this.items.splice(index, 1);
      // }
    },
    downloadFile(item) {
      // if (item.fileUrl) {
      //   window.open(item.fileUrl, "_blank");
      //   // this.$http.downloadFile(item.filePath).then(res => {
      //   //   this.showAlertSuccess("File has been downloaded successfully!");
      //   // });
      // }

      if (this.downloadApproach === downloadApproach.urlInItemPost) {
        if (item.fileUrl) {
          this.$http.downloadFile(item.fileUrl).then((res) => {
            this.showAlertSuccess("File has been downloaded successfully!");
          });
        }
      }

      if (this.downloadApproach === downloadApproach.newTab) {
        if (item.fileUrl) {
          window.open(item.fileUrl, "_blank");
        }
      }
    },
  },
  computed: {
    percent() {
      return Math.round((this.completed / this.total) * 100);
    },
  },
};
</script>
