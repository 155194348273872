<template>
  <v-file-input
    id="fileInput"
    dense
    label="File input"
    @change="$emit('change',$event)"
    :disabled="disabled"
    :multiple="multiple"
  ></v-file-input>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "File input"
    },
    multiple: Boolean,
    disabled: Boolean
  },
  methods: {
    clearFile() {}
  }
};
</script>