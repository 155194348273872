<template>
  <div class="pb-5">
    <v-row>
      <v-col
        offset-md="1"
        md="10"
        sm="12"
      >
        <v-simple-table dense>
          <thead>
            <tr
              class="teal lighten-5"
              v-if="model.canEditMember"
            >
              <th colspan="100%">
                <v-row v-show="value.length < LimitMemberPerTeam">
                  <v-col
                    cols="12"
                    class="d-inline-block pt-10 pl-0"
                  >
                    <render-input
                      ref="addMember"
                      :input="fieldConfig.addMember"
                      v-model="newMembers"
                      :autoFetchItems="false"
                      class="ddl-addmember ml-n2"
                    ></render-input>
                  </v-col>
                  <v-col>
                    <v-chip
                      class="ml-10 mb-3 mt-n10"
                      color="primary"
                      :disabled="!newMembers"
                      text
                      @click="addMembers()"
                    >
                      <v-icon left>mdi-plus</v-icon>Add Data
                    </v-chip>
                  </v-col>
                </v-row>
              </th>
            </tr>
            <tr>
              <th
                class="text-center"
                width="10%"
              >No.</th>
              <!-- <th class="text-center" width="40%">Id</th>
              <th class="text-center" width="30%">Name</th> -->
              <th
                class="text-center"
                width="70%"
              >Name</th>
              <th
                class="text-center"
                width="20%"
              >Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in itemsInTable"
              :key="index"
            >
              <td class="text-center">{{ index + 1 }}</td>
              <!-- <td>{{ item.id }}</td> -->
              <td>{{ item.text }}</td>
              <td class="text-center">
                <v-icon
                  v-if="model.canEditMember"
                  color="error"
                  @click="removeMember(item, index)"
                >mdi-delete</v-icon>
              </td>
            </tr>
            <tr v-show="itemsInTable.length === 0">
              <td
                colspan="100%"
                class="text-center"
              >No Result. Please add</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>

    <!-- <div class="text-right">
      <v-btn
        text
        class="primary mt-10"
        @click="submit()"
        v-if="model.canEditMember"
      >Submit</v-btn>
    </div> -->
  </div>
</template>

<script>
import { InputType, UserRole, LimitMemberPerTeam } from "../js/constants";
import { getToken } from "../js/authorization";

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    model: {
      type: Object,
    },
  },
  data() {
    return {
      LimitMemberPerTeam,
      newMembers: null,
      checked: null,
      allOptions: [],
    };
  },
  watch: {
    filteredOption: {
      handler(newVal) {
        if (this.$refs.addMember) {
          this.$refs.addMember.setItems(newVal);
        }
      },
      deep: true,
    },
    "model.university": {
      handler(newVal) {
        if (newVal) {
          this.getMembers();
        }
      },
      immediate: true,
    },
  },
  mounted() {},
  methods: {
    getMembers() {
      let url = `/dropdown/members?universityId=${this.model.university.id}`;
      this.$http.get(url).then((res) => {
        this.allOptions = res.items;
      });
    },
    addMember(selected) {
      let indexExisting = this.value.findIndex((x) => x.id === selected.id);
      if (indexExisting < 0) {
        let prepareToAdd = Object.assign({}, selected);
        this.value.push(prepareToAdd);
      }
    },
    addMembers() {
      this.newMembers.forEach((newLaw) => {
        this.addMember(newLaw);
      });

      this.newMembers = null;
      this.submit();
    },
    removeMember(item, index) {
      this.value.splice(index, 1);
      this.submit();
    },
    submit() {
      let url = `/team/${this.model.id}/members`;
      let payload = _.cloneDeep(this.value);
      this.$http.put(url, payload).then((res) => {});
    },
  },
  computed: {
    itemsInTable: {
      get() {
        return this.value;
      },
    },
    filteredOption() {
      let existing = this.value.map((x) => {
        return x.id;
      });

      let filtered = this.allOptions.filter((x) => !existing.includes(x.id));
      filtered = _.orderBy(filtered, ["text"], ["asc"]);

      return filtered;
    },
    fieldConfig() {
      return {
        addMember: {
          name: "addMember",
          text: "เพิ่มสมาชิก",
          type: InputType.dropdownMultiple,
        },
      };
    },
  },
};
</script>


<style>
.ddl-addmember .v-autocomplete {
  width: 100%;
}
</style>
