 <template>
  <div>
    <div v-if="model.id">

      <v-chip
        class="ma-2 white"
        color="primary"
        outlined
        label
      >
        <span class="title">Code : {{ model.code }}</span>
      </v-chip>
      {{ model.season }}
      <br>
      <v-chip
        class="ma-2 white red--text red"
        outlined
        label
      >
        <span class="title"> {{ model.toTalBloodCC | currency(0) }} cc</span>
      </v-chip>
      <v-card
        class="mt-5 pb-4"
        :elevation="elevation"
      >
        <v-card-title class="blue--text">
          ข้อมูลทั่วไป
        </v-card-title>
        <v-card-text>
          <custom-secure-block
            ref="headerRows"
            :rows="headerRows"
            v-model="model"
            id="headerRows"
            @click-submit="editHeader()"
            :alwaylsOpen="true"
            :canEdit="model.canEditHeader"
          ></custom-secure-block>

        </v-card-text>
      </v-card>

      <v-card
        class="mt-5 pb-4"
        :elevation="elevation"
      >
        <v-card-title class="blue--text">
          สมาชิกในทีม
        </v-card-title>
        <v-card-text>
          <TableTeamMember
            v-model="model.members"
            :model="model"
          ></TableTeamMember>

        </v-card-text>
      </v-card>

      <v-card
        class="mt-5 pb-4"
        :elevation="elevation"
      >
        <v-card-title class="blue--text">
          กิจกรรม
        </v-card-title>
        <v-card-text>
          <ActivityList
            v-model="model.activities"
            :model="model"
            @submitted-activity="submittedActivity"
          ></ActivityList>

        </v-card-text>
      </v-card>

      <v-card
        class="mt-5 pb-4"
        :elevation="elevation"
      >
        <v-card-title class="blue--text">
          ข้อมูลจากสภากาชาด
        </v-card-title>
        <v-card-text>

          <KachadBloodList
            v-model="model.kachadConfirms"
            :model="model"
            @submitted-kachad="submittedKachad"
          ></KachadBloodList>

        </v-card-text>
      </v-card>
    </div>
    <div v-else-if="loading">
      <v-alert
        border="top"
        colored-border
        type="info"
        elevation="2"
      >
        กำลังโหลด ...
      </v-alert>
    </div>
    <div v-else-if="!model.id && !loading">
      <v-alert
        border="top"
        colored-border
        type="info"
        elevation="2"
      >
        อยู่ระหว่างการระบุทีม โดยเมนทอร์
      </v-alert>
    </div>
  </div>
</template>

<script>
import { isMember } from "../js/authorization";
import { InputType, UserRole } from "../js/constants";
import { createDynamicModel } from "../js/custom";
import TableTeamMember from "./table-team-member.vue";
import ActivityList from "./activity-list.vue";
import KachadBloodList from "./kachad-blood-list.vue";

export default {
  components: {
    TableTeamMember,
    ActivityList,
    KachadBloodList,
  },
  props: {
    teamId: String,
  },
  data() {
    return {
      loading: false,
      model: {
        id: null,
        canEditTeamName: false,
        canEditHeader: false,
        canEditMember: false,
        canEditActivity: false,
        canEditKachad: false,
      },
    };
  },
  created() {
    this.getData();
  },
  mounted() {
    createDynamicModel(this, this.model, this.headerRows);
  },
  methods: {
    editHeader() {
      let url = `/team/${this.teamId}`;
      let payload = _.cloneDeep(this.model);

      this.$http.put(url, payload).then((res) => {
        this.showAlertSuccess();
      });
    },
    getData() {
      let url = "";
      if (isMember()) {
        url = `/team`;
      } else if (this.teamId) {
        url = `/team/${this.teamId}`;
      }

      this.loading = true;

      this.$http
        .get(url)
        .then((res) => {
          this.model = res;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    submittedActivity() {
      this.getData();
    },
    submittedKachad() {
      this.getData();
    },
    calTime(model, val) {
      let url = `/team/calTime?begin=${val}`;
      this.$http.post(url).then((res) => {
        model.end = res;
      });
    },
  },
  computed: {
    canEditHeader() {
      let permission = [
        UserRole.ADMIN,
        UserRole.MENTOR,
        UserRole.SUPER_USER_UNIVERSITY,
      ];
      let result = this.hasPermission(permission);
      return result;
    },
    canEditTeamName() {
      return this.model.canEditTeamName;
    },
    headerRows() {
      return [
        [
          {
            name: "name",
            text: "ชื่อทีม",
            type: InputType.text,
            rule: {
              required: true,
            },
            disabled: !this.canEditTeamName,
            columnClass: "col-12 col-md-4",
          },
          {
            name: "mentor",
            text: "นักธุรกิจจิตอาสา",
            type: InputType.dropdown,
            data: {
              url: "/dropdown/mentors",
            },
            rule: {
              required: true,
            },
            disabled: !this.canEditHeader,
            columnClass: "col-12 col-md-4",
          },
          {
            name: "teacher",
            text: "อาจารย์ที่ปรึกษา",
            type: InputType.dropdown,
            data: {
              url: "/dropdown/teachers",
            },
            rule: {
              required: true,
            },
            disabled: !this.canEditHeader,
            columnClass: "col-12 col-md-4",
          },
          {
            name: "university",
            text: "สถานศึกษา",
            type: InputType.dropdown,
            data: {
              url: "/dropdown/universities",
            },
            rule: {
              required: true,
            },
            disabled: !this.canEditHeader,
            columnClass: "col-12 col-md-8",
          },
          // {
          //   name: "headTeam",
          //   text: "หัวหน้าทีม",
          //   type: InputType.dropdownRelate,
          //   parent: "university",
          //   data: {
          //     url: "/dropdown/headTeams?universityId=",
          //   },
          //   rule: {
          //     required: true,
          //   },
          //   disabled: !this.canEditHeader,
          //   columnClass: "col-12 col-md-4",
          // },
        ],
        [
          // {
          //   name: "date",
          //   text: "ตั้งแต่วันที่",
          //   type: InputType.label,
          //   labelPath: "begin",
          //   columnClass: "col-6 col-md-2",
          // },
          // {
          //   name: "date",
          //   text: "จนถึงวันที่",
          //   type: InputType.label,
          //   labelPath: "end",
          //   columnClass: "col-6 col-md-2",
          // },
          {
            name: "begin",
            text: "ตั้งแต่",
            type: InputType.datePicker,
            columnClass: "col-6 col-md-2",
            disabledIf: (context) => {
              if (!context.model.begin) {
                context.model.end = null;
              } else {
                this.calTime(context.model, context.model.begin);
              }

              return false;
            },
          },
          {
            name: "end",
            text: "จนถึง",
            type: InputType.datePicker,
            disabled: true,
            columnClass: "col-6 col-md-2",
          },
        ],
      ];
    },
  },
};
</script>

<style>
</style>