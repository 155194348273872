 <template>
  <div>
    <v-row class="ml-6 mb-3">
      <v-col>
        <v-btn
          text
          color="primary"
          @click="addRecord()"
          v-if="model.canEditActivity"
        >
          <v-icon>mdi-plus</v-icon> เพิ่มกิจกรรม
        </v-btn>
      </v-col>
    </v-row>

    <ValidationObserver
      ref="observer"
      v-slot="{ valid }"
    >
      <Activity
        class="my-0"
        v-for="(item, index) in value"
        :key="index"
        v-model="value[index]"
        :model="model"
        @click-remove="remove"
        ref="thetikac"
      ></Activity>

      <div class="text-right">
        <v-btn
          text
          class="primary mt-5"
          @click="submit()"
          v-if="model.canEditActivity"
          :disabled="!valid"
        >Submit</v-btn>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import { InputType } from "../js/constants";
import Activity from "./activity.vue";

export default {
  components: {
    Activity,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    model: {
      type: Object,
    },
  },
  created() {
    let teamId = this.$route.params.teamId;
  },
  methods: {
    getData() {
      let url = `/team/${this.model.id}/Activities`;
      this.$http.get(url).then((res) => {
        this.innerValue = res.items;

        // er("submit", this.$refs.thetikac);
        // this.$refs.thetikac.forEach((dd) => {
        //   dd.thetiktest();
        // });
      });
    },
    submit() {
      let url = `/team/${this.model.id}/Activities`;
      let payload = _.cloneDeep(this.value);
      this.$http.put(url, payload).then((res) => {
        this.showAlertSuccess();
        // this.$emit("submitted-activity");
        this.getData();
      });
    },
    addRecord() {
      this.value.push({
        id: "",
        uid: "",
        name: "",
        issueDate: "",
        cost: "",
        note: "",
      });
    },

    remove(item) {
      let index = this.innerValue.findIndex((x) => x.uid === item.uid);
      if (index >= 0) {
        this.innerValue.splice(index, 1);
      }
    },
  },
  computed: {
    innerValue: {
      get() {
        return this.value || [];
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style>
</style>