<template>
  <div
    style="border: 1px solid grey"
    class="pb-12"
  >
    <v-card-text>
      <!-- <div>
        value.uid : {{ value.uid}}
      </div>
      <div>
        uid : {{ uid}}
      </div>
      <div>
        name : {{ value.name}}
      </div>
      <div>
        name : {{ value.issueDate}}
      </div>
      <div>
        id : {{ value.id }}
      </div> -->

      <div class="text-right">
        <v-icon
          class="red--text mt-1 "
          @click="$emit('click-remove',innerValue, index)"
        >mdi-delete</v-icon>
      </div>
      <div class="mb-5"></div>

      <v-row>
        <v-col
          cols="12"
          md="5"
        >
          <v-row>
            <v-col
              cols="12"
              class="my-n2"
            > <render-input
                :input="fieldConfig.confirmDate"
                v-model="innerValue.confirmDate"
                :disabled="!(model.canEditKachad)"
              ></render-input>
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="my-n1"
            > <render-input
                :input="fieldConfig.quantityCC"
                v-model="innerValue.quantityCC"
                :disabled="!(model.canEditKachad)"
              ></render-input>
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col class="green">2</v-col>
          </v-row> -->
        </v-col>
        <v-col>
          <v-row>
            <v-col>
              <render-input
                :input="fieldConfig.note"
                v-model="innerValue.note"
                :disabled="!(model.canEditActivity)"
              ></render-input>
              <!-- <div style="height: 100px;"></div> -->
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <input
            type="file"
            accept="image/*"
            multiple
            @change="fileChanges"
          >

          <v-simple-table
            id="uploadfile-table"
            class="mt-5"
            dense
            :key="forceRerenderRequired"
          >
            <thead>
              <tr>
                <th
                  class="text-center"
                  width="10"
                >No.</th>
                <th>File Name</th>
                <th>Status</th>
                <th
                  class="text-center"
                  width="10"
                >Action</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in fileItems"
                :key="index"
              >
                <td class="text-center">{{ index + 1 }}</td>
                <td>
                  <span v-if="item.fileUrl">
                    <a @click="download(item)">{{ item.fileName }}</a>
                  </span>
                  <span v-else>{{ item.fileName }}</span>
                </td>
                <td :class="{ 'error--text': item.status === statusFile.FAIL }">
                  {{ item.status }}
                </td>
                <td>
                  <v-icon
                    color="error"
                    @click="clickRemoveFile(item, index)"
                  >mdi-delete</v-icon>
                </td>
              </tr>
              <tr
                v-show="fileItems.length === 0"
                class="border-no-result"
              >
                <td
                  colspan="100%"
                  class="text-center"
                >No Result</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import moment from "moment";
import { DATE_PICKER_TYPE, InputType, StatusFile } from "../js/constants";
import {
  momentToThaiFormat,
  toStandardFormat,
  validateThaiFormat,
} from "../js/date-helper";
import { getUID } from "../js/custom";
import KachadBloodList from "./kachad-blood-list.vue";
import UploadAttachmentSection from "./global/upload-attachment-section";
import SimpleGallery from "./SimpleGallery.vue";
import ImagePreview from "./image-preview.vue";
import UploadFileMixins from "./../_mixins/upload-file-mixins";

export default {
  components: {
    KachadBloodList,
    UploadAttachmentSection,
    SimpleGallery,
    ImagePreview,
  },
  mixins: [UploadFileMixins],
  props: {
    value: Object,
    model: {
      type: Object,
    },
  },
  data() {
    return {
      uid: null,
      forceRerenderRequired: 0,
      pendingMode: false,
      statusFile: StatusFile,
      fileItems: [],
    };
  },
  watch: {
    "value.id": {
      handler(newVal) {
        let uploadUrl = "/team/kachad/file";
        let appendForm = Object.assign({}, { kachadId: this.value.id });

        this.pendingMode = true;

        this.uploadFiles(this.fileItems, uploadUrl, appendForm);
      },
    },
  },
  created() {
    this.innerValue.uid = getUID();
    this.uid = this.innerValue.uid;
    this.getData();
  },
  methods: {
    thetiktest() {
      console.log(`uid=${this.innerValue.uid}, id=${this.value.id}`);
    },
    fileChanges(evt) {
      if (!this.value.id) {
        Array.from(evt.target.files).forEach((file) => {
          this.fileItems.push({
            uid: getUID(),
            file: file,
            status: StatusFile.PENDING,
            fileName: file.name,
          });
        });
      } else {
        this.fileItems.push(...this.handleFileChanges(evt.target.files));

        let uploadUrl = "/team/kachad/file";
        this.pendingMode = false;
        let appendForm = Object.assign({}, { kachadId: this.value.id });

        this.uploadFiles(this.fileItems, uploadUrl, appendForm);
      }
    },
    handleUploadSuccess() {
      if (this.uploadFileModel.completed == this.uploadFileModel.total) {
        this.getData();
      }
    },
    getData() {
      if (!this.value.id) {
        return;
      }
      let url = `/team/kachad/${this.value.id}/files`;

      this.$http.get(url).then((res) => {
        this.fileItems = res.items;
      });
    },
    clickRemoveFile(item) {
      if (!item.id) {
        let index = this.fileItems.findIndex((item) => item.uid === uid);
        this.fileItems.splice(index, 1);
        return;
      }

      let url = `/team/kachad/File?fileId=${item.id}`;

      this.$http.delete(url).then((res) => {
        let removedItems = _.remove(this.fileItems, (image) => {
          return image.id === item.id;
        });

        this.forceRerenderRequired++;
      });
    },
  },
  computed: {
    innerValue: {
      get() {
        return this.value || [];
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    fieldConfig() {
      return {
        confirmDate: {
          name: "confirmDate",
          text: "วันที่รับแจ้ง",
          type: InputType.datePicker,
          rule: {
            required: true,
          },
        },
        quantityCC: {
          name: "quantityCC",
          text: "จำนวนเลือด (CC)",
          type: InputType.number,
          decimal: 0,
          rule: {
            required: true,
          },
        },
        note: {
          name: "note",
          text: "หมายเหตุ",
          type: InputType.textArea,
        },
      };
    },
  },
};
</script>

<style>
</style>