 <template>
  <div>
    <div v-if="previewImage">
      <img
        :src="previewImage"
        alt="Preview"
        width="160"
        height="160"
      />

      <i
        class="mdi mdi-delete thetik-icon d-inline-block"
        @click="$emit('click-delete',uid)"
        v-on="on"
      ></i>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    file: Object,
    uid: String,
  },
  data() {
    return {
      previewImage: null,
    };
  },
  watch: {
    file: {
      handler(newVal) {
        const reader = new FileReader();

        // When the file is read successfully
        reader.onload = (e) => {
          this.previewImage = e.target.result;
        };

        // Read the file as a data URL (base64 encoded)
        reader.readAsDataURL(newVal);
      },
      immediate: true,
    },
  },
  methods: {
    // Function to handle the file input change event
    handleFileSelect(evt) {
      const file = evt.target.files[0];
      const reader = new FileReader();

      // When the file is read successfully
      reader.onload = (e) => {
        this.previewImage = e.target.result;
      };

      // Read the file as a data URL (base64 encoded)
      reader.readAsDataURL(file);
    },
  },
};
</script>

<style>
.thetik-icon {
  border: 3px solid black;
  margin: 0px;
  padding-left: 2px;
  padding-right: 2px;
}

.thetik-icon::before {
  display: table-row;
}
</style>